import React, { useState } from 'react';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import 'react-toastify/dist/ReactToastify.css';

function ChangePassword(props) {
    //const inputDomain = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    var schema;
    schema = yup.object().shape({
        password: yup.string().required(),
        new_password: yup.string().required(),
        new_password_confirmation: yup.string().required()
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = useState(false);

    const onSubmitHandler = async (data) => {
        //console.log(data);
        setLoading(true);

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('user')
        };

        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'change-password', data, {
                headers: headers
            });

            if (response.data.status === 1) {
                toast.success(response.data.message, {
                    //onClose: () => { navigate('/ChangePassword') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                reset();
                setLoading(false);
            }
            else if (response.data.status === -1) {
                localStorage.removeItem('user');
                toast.error(response.data.message, {
                    onClose: () => { navigate('/Login') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                const msg = JSON.parse(response.data.message);
                Object.keys(msg).forEach((key) => {
                    msg[key].forEach((item) => {
                        toast.error(item, {
                            position: "top-right",
                            autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                    });
                });

                // Custom toast message with HTML
                // const Msg = ({ closeToast, toastProps }) => (
                //     <div className='toast-multi-line'>
                //         {msg_html.map((msgLine)=>
                //             <div>{msgLine}</div>
                //         )}

                //        {/* {toastProps.position} */}

                //       {/* <button>Retry</button>
                //       <button onClick={closeToast}>Close</button> */}
                //     </div>
                //   )            
                //   toast(<Msg />) 

                setLoading(false);
            }
        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
    };

    
    return (
        <div>
            <ToastContainer />

            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="form-control">
                    
                    <TextField {...register("password")} type="password" id="password" label="Current Password" variant="outlined" />
                    <p>{errors.password?.message}</p>
                </div>
                <div className="form-control">
                    
                    <TextField {...register("new_password")} type="password" id="email" variant="outlined" label="New Password" />
                    <p>{errors.current_password?.message}</p>
                </div>
                <div className="form-control">
                    
                    <TextField {...register("new_password_confirmation")} type="password" id="new_password_confirmation" label="Confirmed Password" variant="outlined" />
                    <p>{errors.password_confirmation?.message}</p>
                </div>

                <div className="form-control">
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained">
                        <span>Save</span>
                    </LoadingButton>
                </div>

            </form>
        </div>
    )
}

export default ChangePassword;