import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Moment from 'react-moment';
import ThemeUpdateLog from "../components/ThemeUpdateLog";
import ScoreUpdate from "../components/ScoreUpdate";

import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import HistorySharpIcon from '@mui/icons-material/HistorySharp';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SpeedSharpIcon from '@mui/icons-material/SpeedSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScoreSharpIcon from '@mui/icons-material/ScoreSharp';


function StoreList(props) {

    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const ThemeUpdateLogRef = useRef();
    const ScoreUpdateRef = useRef();
    const [open, setOpen] = React.useState(false);
    const [deleteShopId, setDeleteShopId] = useState();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const deleteShop = (params) => {
        setDeleteShopId(params.id);
        setOpen(true);
    };

    const handleClick = (params) => {
        navigate('/GenerateBlackList/' + params.row.id);
        //console.log(params.row.id);
    }
    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        {
        field: 'status', headerName: '', width: 50, sortable: false, headerAlign: 'center', disableColumnFilter: true, disableColumnMenu: true, showColumnRightBorder: false, 
        renderCell: (params) => (
                <div className={((params.row.before_mobile === 0 || params.row.before_desktop === 0 || params.row.after_mobile === 0 || params.row.after_desktop === 0 || params.row.app_mobile === 0 || params.row.app_desktop === 0)) ? 'score-status-cell-red' : 'score-status-cell-green'}>
                </div>
            )
        },        
        {
            field: 'tools', headerName: 'Tools', width: 140, sortable: true, headerAlign: 'center',
            renderCell: (params) => (
                <strong>
                    <Link className="data-grid-action-link" onClick={() => {
                        handleClick(params);
                    }}>
                        <IconButton
                            aria-label="Process Theme"
                            color="error">
                            <SpeedSharpIcon />
                        </IconButton>

                    </Link>

                    <Link className="data-grid-action-link" 
                        onClick={() => {
                            ScoreUpdateRef.current.handleShopClick(params.id, params.row.domain);
                            ScoreUpdateRef.current.handleOpen();
                            //setOpen(true);
                        }}
                    >
                        <IconButton
                            aria-label="Score"
                            color="success">
                            <ScoreSharpIcon />
                        </IconButton>

                    </Link>
                    
                    <Link className="data-grid-action-link"
                        onClick={() => {
                            ThemeUpdateLogRef.current.handleShopClick(params.id, params.row.domain);
                            ThemeUpdateLogRef.current.handleOpen();
                            //setOpen(true);
                        }}
                    >
                        <IconButton
                            aria-label="History"
                            color="warning"
                        >
                            <HistorySharpIcon />
                        </IconButton>

                    </Link>
                </strong>
            )
        },        
        { field: 'id', headerName: 'ID', width: 70, sortable: true },
        { field: 'domain', headerName: 'Domain', width: 270, sortable: true },
        { field: 'api_key', headerName: 'API Key', width: 300, sortable: false },
        { field: 'api_secret', headerName: 'API Secret', width: 300, sortable: false  },
        { field: 'api_password', headerName: 'API Password', width: 350, sortable: false  },
        { field: 'name', headerName: 'User', width: 100, sortable: false  },
        {
            field: 'created_at', headerName: 'Created At', width: 200, sortable: true,
            renderCell: (params) => (
                <Moment format="DD-MM-YYYY kk:mm:ss A">
                    {params.row.created_at}
                </Moment>
            )
        },
        {
            field: 'action', headerName: 'Action', width: 100, sortable: true, headerAlign: 'center',
            renderCell: (params) => (
                <strong>
                    <Link className="data-grid-action-link" underline="hover"
                        onClick={() => {
                            navigate('/CreateStore/' + params.id);
                        }}
                    >
                        <IconButton
                            aria-label="edit"
                            color="success"
                        >
                            <EditIcon />
                        </IconButton>

                    </Link>
                    <Link className="data-grid-action-link" underline="hover"
                        onClick={() => {
                            deleteShop(params);
                        }}
                    >
                    <IconButton
                        aria-label="delete"
                        color="error"
                    >
                        <DeleteIcon />
                    </IconButton>
                    </Link>
                </strong>
            )
        },        

    ];

    const handleDeleteShop = (shopId) => {
        async function deleteShop() {
            try {
                const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'shop/delete/' + shopId, null, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('user')
                    }
                });

                if (response.data.status === 1) {
                    setRows(JSON.parse(response.data.data));
                    handleClose();
                }
                else if (response.data.status === -1) {
                    localStorage.removeItem('user');
                    toast.error(response.data.message, {
                        onClose: () => { navigate('/Login') },
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (response.data.status === 0) {
                    toast.error(response.data.message, {
                        onClose: () => { handleClose() },
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            } catch (error) {
                toast.error(error.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        }

        deleteShop();
    }    

    useEffect(() => {

        async function getStores() {
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + "shops", {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('user')
                    }
                });

                if (response.data.status === 1) {
                    setRows(JSON.parse(response.data.data));
                }
                else if (response.data.status === -1) {
                    localStorage.removeItem('user');
                    toast.error(response.data.message, {
                        onClose: () => { navigate('/Login') },
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                }
                else if (response.data.status === 0) {
                    toast.error(response.data.message, {
                        //onClose: () => 
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                }
            } catch (error) {
                toast.error(error.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        }

        getStores();
    }, [navigate])


    return (
        <Container maxWidth="false">
            <div style={{ width: '100%' }}>
                <ToastContainer />
                <ThemeUpdateLog ref={ThemeUpdateLogRef} />
                <ScoreUpdate ref={ScoreUpdateRef} />

                <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Store Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete Store ID {deleteShopId}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="error" autoFocus onClick={() => { handleDeleteShop(deleteShopId) }}>
                        Yes
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
                <div style={{ textAlign: 'right', marginBottom: 20 }}>
                    <Button
                        variant="contained"
                        onClick={() => { navigate('/CreateStore') }}
                        color="success"
                        startIcon={<AddCircleIcon />}
                    >Create a Store</Button>
                </div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}

                    />
                </div>
            </div>
        </Container>
    )

}

export default StoreList;