import React, { useState } from 'react';

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';


function GenerateBlackList(props) {
    const { shop_id } = useParams();

    const schema = yup.object().shape({
        theme_id: yup.number().required().moreThan(0)
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = React.useState(false);
    const [themeId, setThemeId] = React.useState(0);
    const [themeList, setThemeList] = React.useState([{ id: 0, name: 'Select Theme' }]);
    const [step, setStep] = React.useState(1);
    const [blackListURL, setBlackListURL] = React.useState();
    const [domain, setDomain] = useState();
    const navigate = useNavigate();

    // const handleChange = (event) => {
    //     console.log(event.target.value);
    //     setThemeId(event.target.value);
    // };

    const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('user')
    };

    const onSubmitHandler = async (data) => {
        setLoading(true);

        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'theme/add-initial-js/' + shop_id + "/" + themeId, {
                headers: headers
            });

            if (response.data.status === 1) {
                toast.success(response.data.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoading(false);
                reset();
                setBlackListURL(response.data.data);
                setStep(2);
            }
            else if (response.data.status === -1) {
                localStorage.removeItem('user');
                toast.error(response.data.message, {
                    onClose: () => { navigate('/Login') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            }
            else if (response.data.status === 0) {
                toast.error(response.data.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            }

        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
    };

    const handleButtonClick = async (data) => {
        window.open(blackListURL, '_blank');
        navigate('/UpdateTheme/' + shop_id + '/' + themeId, { replace: true });
        //return <Redirect to="/UpdateTheme/1/1" />;
    };
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'shop/' + shop_id, {
            headers: headers
        })
            .then((response) => {
                setDomain(JSON.parse(response.data.data).domain)
                axios.get(process.env.REACT_APP_API_BASE_URL + 'theme/list/' + shop_id, {
                    headers: headers
                })
                    .then((response) => {

                        var themes = JSON.parse(response.data.data);
                        if (themes)
                        {
                            themes.sort(function (a, b) {    // Sorting on theme name
                                return a.name.localeCompare(b.name)
                            });
                            themes = [...[{ id: 0, name: 'Select Theme' }], ...themes];
                            setThemeList(themes);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.message, {
                            //onClose: () => 
                            position: "top-right",
                            autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    });

            })
            .catch((error) => {
                toast.error(error.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });

        //
    }, [shop_id]);

    return (
        <div>
            <ToastContainer />
            <div style={{ padding: '30px 10px' }}>{domain}</div>
            {step === 1 &&
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="form-control">
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="theme_id_label">Store Themes</InputLabel>
                            <Select
                                id="theme_id"
                                labelId='theme_id_label'
                                label="Select your theme"
                                value={themeId}
                                //onChange={handleChange} // With yup "register" onChange will not work as "register" will override the onchange
                                {...register("theme_id", {
                                    onChange: (event) => {
                                        setThemeId(event.target.value);
                                    }
                                })}
                            >
                                {themeList.map((theme) => (
                                    <MenuItem key={theme.id}
                                        value={theme.id}>
                                        {theme.name}
                                    </MenuItem>
                                ))};
                            </Select>
                            <p>{errors.theme_id?.message}</p>
                        </FormControl>
                    </div>
                    <div className="form-control">
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained">
                            <span>Go</span>
                        </LoadingButton>
                    </div>

                </form>
            }
            {step === 2 &&
                <div>
                    <Button
                        onClick={handleButtonClick}
                        type="button"
                        startIcon={<SaveIcon />}
                        variant="contained">
                        <span>Get Black List JSS/CSS</span>
                    </Button>
                    <Typography variant="caption" display="block" gutterBottom>
                        Info: After clicking "Get Black List JSS/CSS" button wait for the message "Black list has been updated successfully!" in browser to proccess next step.
                    </Typography>
                </div>
            }
        </div>
    )
}

export default GenerateBlackList;