import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
    const user = localStorage.getItem('user');
    if (user)
    {
        return true;
        // const headers = {
        //     'Authorization': 'Bearer ' + user
        // };
        // const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'verify-token', [], {
        //     headers : headers
        // });

        
        // if (response.data.status == 1)
        //     return true;
        // else
        //     return false;
    }
    else
        return false;
}

function ProtectedRoutes(props) {
    const auth = useAuth();
    const userRole = localStorage.getItem('user_role');
    if (!userRole || props.requiredRole.includes(userRole))
        return auth ? <Outlet/> : <Navigate to="/Login" />
    else
        return <Navigate to="/AccessDenied" />
}

export default ProtectedRoutes;