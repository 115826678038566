import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';

function ThemeUpdateLog(props) {
    const { update_theme_log_id } = useParams();
    // const [fileScanned, setFileScanned] = useState();
    // const [filesHTML, setFilesHTML] = useState();

    //useEffect(() => {
        // axios.get(process.env.REACT_APP_API_BASE_URL + 'theme-update-log/' + update_theme_log_id)
        //     .then((response) => {

        //         //console.log(JSON.parse(response.data.data));
        //         var updateReportJson = JSON.parse(JSON.parse(response.data.data).update_report);
        //         //setUpdateReport(JSON.parse(JSON.parse(response.data.data).update_report));
        //         setFileScanned(JSON.parse(response.data.data).liquid_hierarchy);
        //         console.log(JSON.parse(JSON.parse(response.data.data).update_report));

        //         //setUpdateReportKeys(Object.keys(JSON.parse(JSON.parse(response.data.data).update_report)));
        //         //setUpdateReportValues(Object.values(JSON.parse(JSON.parse(response.data.data).update_report)));

        //         var updateReportHTML = '<table>';
        //         Object.keys(updateReportJson).forEach(function (key) {
        //             //arr.push(updateReportJson[key]);
        //             updateReportHTML += '<tr><td><h2>' + key + '</h2></td></tr>';
        //             //console.log(key);
        //             // if (updateReportJson[key].script_type_changed || updateReportJson[key].src_replaced)
        //             //     updateReportHTML += "<table>";
        //             if (updateReportJson[key].script_type_changed) {
        //                 updateReportHTML += '<td><h3>Script Type Changed</h3></td>';
        //                 updateReportJson[key].script_type_changed.forEach((item) => {
        //                     updateReportHTML += '<tr><td>' + item.replace('<', '&lt;').replace('>', '&gt;') + '</td></tr>';
        //                     //console.log(item);
        //                 })
        //                 //console.log(updateReportJson[key].script_type_changed[0]);
        //             }
        //             if (updateReportJson[key].src_replaced) {
        //                 updateReportHTML += '<td><h3>Script SRC Replaced</h3></td>';
        //                 updateReportJson[key].src_replaced.forEach((item) => {
        //                     updateReportHTML += '<tr><td>' + item + '</td></tr>';
        //                     //console.log(item);
        //                 });
        //                 //console.log(updateReportJson[key].src_replaced[0]);
        //             }
        //             // if (updateReportJson[key].script_type_changed || updateReportJson[key].src_replaced)
        //             //     updateReportHTML += "</table>";

        //         })
        //         updateReportHTML += "</table>";
        //         setFilesHTML(updateReportHTML);
        //         //setFileScanned("<b>Hello</b>World!!!");
        //         //console.log(JSON.parse(response.data.data).liquid_hierarchy);
        //         // var themes = JSON.parse(response.data.data);
        //         // themes.sort(function (a, b) {    // Sorting on theme name
        //         //     return a.name.localeCompare(b.name)
        //         // });
        //         // themes = [...[{ id: 0, name: 'Select Theme' }], ...themes];
        //         // setThemeList(themes);
        //     })
        //     .catch((error) => {
        //         toast.error(error.message, {
        //             //onClose: () => 
        //             position: "top-right",
        //             autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //             theme: "colored",
        //         });
        //     });
    //}, [update_theme_log_id])

    return (
        <div>
            <Link href={process.env.REACT_APP_API_BASE_URL + "change-log/" + update_theme_log_id}>
                    <IconButton
                        aria-label="Download Change Log"
                        color="success">
                        <DownloadForOfflineSharpIcon />
                    </IconButton>
                    Change Log</Link>
                    <Link href={process.env.REACT_APP_API_BASE_URL + "scan-log/" + update_theme_log_id}>
                    <IconButton
                        aria-label="Download Change Log"
                        color="warning">
                        <DownloadForOfflineSharpIcon />
                    </IconButton>
                    Scan Log</Link>

            {/* <div dangerouslySetInnerHTML={{ __html: filesHTML }}></div>
            <div dangerouslySetInnerHTML={{ __html: fileScanned }}></div> */}
        </div>
    );
}

export default ThemeUpdateLog;