import React from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ToastContainer, toast } from "react-toastify";

function UpdateTheme(props) {
    const { shop_id, theme_id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [domain, setDomain] = useState();
    const [state, setState] = useState({ blockScriptSRC:true, lazyLoading: true, blockInlineJS: true, blockInjectedCSS: true, blockScriptSRCVal: 1, lazyLoadingVal: 1, blockInlineJSVal: 1, blockInjectedCSSVal: 1 });
    const navigate = useNavigate();

    const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('user')
    };

    const handleChangeCheckBox = (event) => {
        const ctrlName = event.target.name;
        const checked = event.target.checked;
        const value = parseInt(event.target.value);
        const ctrlNameVal = event.target.name + "Val";

        setState({ ...state, [ctrlName]: checked, [ctrlNameVal]: value });

    };
    const handleButtonOnClick = async () => {
        try {
            setLoading(true);
            
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'theme/update/' + shop_id + "/" + theme_id, { use_lazyload: (state.lazyLoading ? state.lazyLoadingVal : 0), block_inline_script: (state.blockInlineJS ? state.blockInlineJSVal : 0), block_script_src: (state.blockScriptSRC ? state.blockScriptSRCVal : 0), block_css_injected: (state.blockInjectedCSS ? state.blockInjectedCSSVal : 0) }, {
                headers: headers
            });
            setLoading(false);
            if (response.data.status === 1) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                const theme_update_log = JSON.parse(response.data.data);
                navigate('/ThemeUpdateLog/' + theme_update_log.theme_update_log_id);
            }
            else if (response.data.status === -1) {
                localStorage.removeItem('user');
                toast.error(response.data.message, {
                    onClose: () => { navigate('/Login') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            }
            else if (response.data.status === 0) {
                toast.error(response.data.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            }
        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'shop/' + shop_id, {
            headers: headers
        })
            .then((response) => {

                if (response.data.status === 1) {
                    setDomain(JSON.parse(response.data.data).domain)
                }
                else if (response.data.status === -1) {
                    localStorage.removeItem('user');
                    toast.error(response.data.message, {
                        onClose: () => { navigate('/Login') },
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                }
                else if (response.data.status === 0) {
                    toast.error(response.data.message, {
                        //onClose: () => 
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                }
            })
            .catch((error) => {
                toast.error(error.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });

    }, [shop_id])

    return (
        <div>
            <ToastContainer />
            <div style={{ padding: '20px 10px' }}>{domain}</div>
            <div>
                <LoadingButton
                    type="button"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    onClick={handleButtonOnClick}
                    variant="contained">
                    <span>Update Theme Files</span>
                </LoadingButton>
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox checked={state.blockScriptSRC} onChange={handleChangeCheckBox} name="blockScriptSRC" id="blockScriptSRC" value={1} />
                    }
                    label="Block Script Tag with SRC?"
                />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox checked={state.blockInlineJS} onChange={handleChangeCheckBox} name="blockInlineJS" id="blockInlineJS" value={1} />
                    }
                    label="Block Inline JS Script Tag?"
                />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox checked={state.blockInjectedCSS} onChange={handleChangeCheckBox} name="blockInjectedCSS" id="blockInjectedCSS" value={1} />
                    }
                    label="Block Injected CSS?"
                />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox checked={state.lazyLoading} onChange={handleChangeCheckBox} name="lazyLoading" id="lazyLoading" value={1} />
                    }
                    label="Apply Lazyload?"
                />
            </div>
        </div>
    );
}

export default UpdateTheme;