import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import CreateStore from './Pages/CreateStore';
import GenerateBlackList from './Pages/GenerateBlackList';
import UpdateTheme from './Pages/UpdateTheme';
import ThemeUpdateLog from './Pages/ThemeUpdateLog';
import Login from './Pages/Login';
import ProtectedRoutes from './components/ProtectedRoutes';
import PublicRoutes from './components/PublicRoutes';
import StoreList from './Pages/StoreList';
import UserList from './Pages/UserList';
import CreateUser from './Pages/CreateUser';
import AccessDenied from './Pages/AccessDenied';
import ChangePassword from './Pages/ChangePassword';

const Header = React.lazy(() => import('./components/Header'));

function App() {
  
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<ProtectedRoutes requiredRole={[process.env.REACT_APP_ROLE_USER, process.env.REACT_APP_ROLE_ADMIN]} />}>
          <Route path="Dashboard" element={<Dashboard />}></Route>
          <Route path="StoreList" element={<StoreList />}></Route>
          <Route path="CreateStore/:Id?" element={<CreateStore />}></Route>
          <Route path="GenerateBlackList/:shop_id" element={<GenerateBlackList />}></Route>
          <Route path='UpdateTheme/:shop_id/:theme_id' element={<UpdateTheme />}></Route>
          <Route path='ThemeUpdateLog/:update_theme_log_id' element={<ThemeUpdateLog />}></Route>
          <Route path='ChangePassword' element={<ChangePassword />}></Route>
        </Route>
        <Route path='/' element={<ProtectedRoutes requiredRole={[process.env.REACT_APP_ROLE_ADMIN]} />}>
          <Route path="UserList" element={<UserList />}></Route>
          <Route path="CreateUser/:Id?" element={<CreateUser />}></Route>
        </Route>
        <Route path='/' element={<PublicRoutes />}>
          <Route path='Login' element={<Login />}></Route>
          <Route path='AccessDenied' element={<AccessDenied />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
