import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const useAuth = () => {
    const user = localStorage.getItem('user');

    if (user)
    {
        return true;
        // const headers = {
        //     'Authorization': 'Bearer ' + user
        // };
        // const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'verify-token', [], {
        //     headers : headers
        // });

        // console.log(response);
        // if (response.data.status == 1)
        //     return true;
        // else
        //     return false;
    }
    else
        return false;
}

function PublicRoutes(props) {
    const auth = useAuth();
    const location = useLocation();

    if (location.pathname.toLowerCase() === '/login' && auth)
        return <Navigate to='/Dashboard' />

    //return auth ? <Navigate to='/Dashboard' /> : <Outlet />
    return <Outlet />
}

export default PublicRoutes;