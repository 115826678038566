import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import LoadingButton from '@mui/lab/LoadingButton';
import KeyIcon from '@mui/icons-material/Key';
import { TextField } from "@mui/material";
import FormControl from '@mui/material/FormControl';
//import InputLabel from '@mui/material/InputLabel';


function Login(props) {
    const [loading, setLoading] = useState();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required()
    });

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    const onSubmitHandler = async (data) => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'login', data)
            if (response.data.status === 1) {
                localStorage.setItem('user', response.data.data.token);
                localStorage.setItem('user_role', response.data.data.user.role_id);
                //console.log(JSON.parse(Buffer.from(response.data.data.token.split('.')[1], 'base64').toString()));
                //console.log(jwtDecode(response.data.data.token));
                //console.log(response.data.data.user.role_id);
                navigate('/Dashboard');
            }
            else {
                toast.error(response.data.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoading(false);
            }
        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
        //alert('Submit called!');
    };

    useEffect(() => {
        // const user = localStorage.getItem('user');
        // if (user)
        //     navigate('/Dashboard');

    }, []);

    return (
        <div>
            <ToastContainer />
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="form-control">
                    <FormControl sx={{ minWidth: 200 }}>
                        
                        <TextField {...register("email")} id="email" variant="outlined" label="Email" />
                        <p>{errors.email?.message}</p>
                    </FormControl>
                </div>
                <div className="form-control">
                    <FormControl sx={{ minWidth: 200 }}>
                        
                        <TextField {...register("password")} id="password" type="password" variant="outlined" label="Password" />
                        <p>{errors.password?.message}</p>
                    </FormControl>
                </div>
                <div className="form-control">
                    <FormControl sx={{ minWidth: 200 }}>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<KeyIcon />}
                            variant="contained"
                        >
                            <span>Submit</span>
                        </LoadingButton>
                    </FormControl>
                </div>
            </form>
        </div>
    );
}

export default Login;