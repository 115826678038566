import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const ScoreUpdate = forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [shopId, setShopId] = useState(0);
    const [domain, setDomain] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
        handleShopClick(shopId, domainName) {
            setShopId(shopId);
            setDomain(domainName);
            getStore(shopId);
            //getThemeUpdateLog(shopId);
        }
    }));

    const themeNew = createTheme({
        palette: {
          neutral: {
            main: '#64748B',
            contrastText: '#fff',
          },
        },
      });    

    const handleClose = () => {
        setOpen(false);
        setState({ before_mobile: '', before_desktop: '', after_mobile: '', after_desktop: '', app_mobile: '', app_desktop: '', no_of_apps: '', low_scoring_theme: false, comments: '' });
        reset({ before_mobile: '', before_desktop: '', after_mobile: '', after_desktop: '', app_mobile: '', app_desktop: '', no_of_apps: '', low_scoring_theme: false, comments: '' });

    };

    const schema = yup.object().shape({
        before_mobile: yup.number().max(100),
        before_desktop: yup.number().max(100),
        after_mobile: yup.number().max(100),
        after_desktop: yup.number().max(100),
        app_mobile: yup.number().max(100),
        app_desktop: yup.number().max(100),
        no_of_apps: yup.number(),
        comments: yup.string().when("low_scoring_theme", {
            is: true,
            then: yup.string().required("Must comments when Low Scoring Theme is 'Yes'")
          })
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = useState({ before_mobile: '', before_desktop: '', after_mobile: '', after_desktop: '', app_mobile: '', app_desktop: '', no_of_apps: '', low_scoring_theme: false, comments: '' });

    const handleChange = (event) => {
        const ctrlName = event.target.name;
        const value = event.target.value;
        setState({ ...state, [ctrlName]: value });
        reset({ ...state, [ctrlName]: value });
    };

    const handleCheckboxChange = (event) => {
        const ctrlName = event.target.name;
        const value = event.target.checked;
        setState({ ...state, [ctrlName]: value });
        reset({ ...state, [ctrlName]: value });
    };


    const onSubmitHandler = async (data) => {
        //console.log(data); return;
        setLoading(true);

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('user')
        };

        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'shop/update-score/' + shopId, data, {
                headers: headers
            });

            if (response.data.status === 1) {
                toast.success(response.data.message, {
                    onClose: () => { setOpen(false) },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                reset();
                setLoading(false);
            }
            else if (response.data.status === -1) {
                localStorage.removeItem('user');
                toast.error(response.data.message, {
                    onClose: () => { navigate('/Login') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                const msg = JSON.parse(response.data.message);
                Object.keys(msg).forEach((key) => {
                    msg[key].forEach((item) => {
                        toast.error(item, {
                            position: "top-right",
                            autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                    });
                });
                setLoading(false);
            }
        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
    };

    async function getStore(shopId) {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'shop/' + shopId, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('user')
                }
            });

            if (response.data.status === 1) {
                //setRows(JSON.parse(response.data.data));
                const store = JSON.parse(response.data.data);
                setState({ before_mobile: store.before_mobile, before_desktop: store.before_desktop, after_mobile: store.after_mobile, after_desktop: store.after_desktop, app_mobile: store.app_mobile, app_desktop: store.app_desktop, no_of_apps: store.no_of_apps, low_scoring_theme: Boolean(store.low_scoring_theme), comments: (store.comments === null) ? '' : store.comments });
                reset({ before_mobile: store.before_mobile, before_desktop: store.before_desktop, after_mobile: store.after_mobile, after_desktop: store.after_desktop, app_mobile: store.app_mobile, app_desktop: store.app_desktop, no_of_apps: store.no_of_apps, low_scoring_theme: Boolean(store.low_scoring_theme), comments: (store.comments === null) ? '' : store.comments });
            }
            else if (response.data.status === -1) {
                localStorage.removeItem('user');
                toast.error(response.data.message, {
                    onClose: () => { navigate('/Login') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else if (response.data.status === 0) {
                toast.error(response.data.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    useEffect(() => {
    }, [shopId]);

    return (
        <div>
            <ToastContainer />
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'lg'}
                open={open}
                //onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Speed Optimization
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogTitle id="responsive-dialog-title">
                        {domain}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ height: 400, width: '100%' }}>

                            <div className="scores">
                                {/* <div className="title">Site Score</div> */}
                                <div className="row">
                                    <div className="title">Before</div>
                                    <div className="form-control">
                                        <TextField error={(errors.before_mobile) ? true : false} {...register("before_mobile")} id="before_mobile" label="Mobile Score" variant="outlined" value={state.before_mobile} onChange={handleChange} />
                                    </div>
                                    <div className="form-control">
                                        <TextField error={(errors.before_desktop) ? true : false} {...register("before_desktop")} id="before_desktop" label="Desktop Score" variant="outlined" value={state.before_desktop} onChange={handleChange} />
                                        {/* <div className="error">{errors.before_desktop?.message}</div> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="title">After</div>
                                    <div className="form-control">
                                        <TextField error={(errors.after_mobile) ? true : false} {...register("after_mobile")} id="after_mobile" label="Mobile Score" variant="outlined" value={state.after_mobile} onChange={handleChange} />
                                    </div>
                                    <div className="form-control">
                                        <TextField error={(errors.after_desktop) ? true : false} {...register("after_desktop")} id="after_desktop" label="Desktop Score" variant="outlined" value={state.after_desktop} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="title">App</div>
                                    <div className="form-control">
                                        <TextField error={(errors.app_mobile) ? true : false} {...register("app_mobile")} id="app_mobile" label="Mobile Score" variant="outlined" value={state.app_mobile} onChange={handleChange} />
                                    </div>
                                    <div className="form-control">
                                        <TextField error={(errors.app_desktop) ? true : false} {...register("app_desktop")} id="app_desktop" label="Desktop Score" variant="outlined" value={state.app_desktop} onChange={handleChange} />
                                    </div>
                                </div>
                                <Divider className="divider" />
                            </div>
                            <div>
                                <div className="form-control">
                                    <TextField error={(errors.no_of_apps) ? true : false} {...register("no_of_apps")} id="no_of_apps" label="Number of Apps" variant="outlined" value={state.no_of_apps} onChange={handleChange} />
                                </div>

                                <div className="form-control">
                                    <FormControlLabel control={<Checkbox {...register("low_scoring_theme")} id="low_scoring_theme" checked={state.low_scoring_theme} onChange={handleCheckboxChange} />} label="Low Scoring Theme?" />
                                </div>
                            </div>
                            <div className="score-comments">
                                <TextField error={(errors.comments) ? true : false} {...register("comments")} fullWidth id="comments" label="Comments" variant="outlined" multiline value={state.comments} onChange={handleChange} />
                            </div>
                            {/* <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                //onClick={onSubmitHandler}
                                variant="contained">
                                <span>Save</span>
                            </LoadingButton> */}



                        </div>
                    </DialogContent>
                    <ThemeProvider theme={themeNew}>
                    <AppBar sx={{ position: 'relative' }} color="">
                        <Toolbar>
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                color="success"
                                variant="contained">
                                <span>Save</span>
                            </LoadingButton>
                        </Toolbar>
                    </AppBar>
                    </ThemeProvider>
                </form>
            </Dialog>

        </div>
    );
})

export default ScoreUpdate;