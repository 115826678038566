import React from 'react';

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function CreateStore(props) {
    //const inputDomain = useRef<HTMLInputElement>(null);
    const { Id } = useParams();
    const navigate = useNavigate();
    const schema = yup.object().shape({
        domain: yup.string().required(),
        api_key: yup.string().required(),
        api_secret: yup.string().required(),
        api_password: yup.string().required()
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [state, setState] = useState({ domain: '', api_key: '', api_secret: '', api_password: '' });

    const handleChange = (event) => {
        const ctrlName = event.target.name;
        const value = event.target.value;
        setState({ ...state, [ctrlName]: value });
        reset({ ...state, [ctrlName]: value });
    }

    const onSubmitHandler = async (data) => {
        //console.log(data);
        setLoading(true);

        // Not working loading msg
        // const response = await toast.promise(
        //     axios.post('https://localhost/shopify-speed-optimize-staging/public/api/shop/create', data),
        //     {
        //         loading: 'Loading',
        //         success: 'Got the data',
        //         error: 'Error when fetching',
        //     }
        // );
        // const promise = axios.post('https://localhost/shopify-speed-optimize-staging/public/api/shop/create', data);
        // toast.promise(promise, {
        //     loading: 'Loading',
        //     success: 'Got the data',
        //     error: 'Error when fetching',
        // });
        // const response = await promise;

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('user')
        };

        try {
            var url;
            if (Id)
                url = process.env.REACT_APP_API_BASE_URL + 'shop/create/' + Id;
            else
                url = process.env.REACT_APP_API_BASE_URL + 'shop/create';

            const response = await axios.post(url, data, {
                headers: headers
            });

            if (response.data.status === 1) {
                toast.success(response.data.message, {
                    onClose: () => { navigate('/StoreList') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                reset();
                setLoading(false);
            }
            else if (response.data.status === -1) {
                localStorage.removeItem('user');
                toast.error(response.data.message, {
                    onClose: () => { navigate('/Login') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                const msg = JSON.parse(response.data.message);
                Object.keys(msg).forEach((key) => {
                    msg[key].forEach((item) => {
                        toast.error(item, {
                            position: "top-right",
                            autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                    });
                });
                setLoading(false);
            }
        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
    };
    useEffect(() => {

        async function getStore() {
            try {
                setLoader(true);
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'shop/' + Id, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('user')
                    }
                });

                if (response.data.status === 1) {
                    //setRows(JSON.parse(response.data.data));
                    const store = JSON.parse(response.data.data);
                    setState({ domain: store.domain, api_key: store.api_key, api_secret: store.api_secret, api_password: store.api_password });
                    reset({ domain: store.domain, api_key: store.api_key, api_secret: store.api_secret, api_password: store.api_password });
                    setLoader(false);
                }
                else if (response.data.status === -1) {
                    localStorage.removeItem('user');
                    toast.error(response.data.message, {
                        onClose: () => { navigate('/Login') },
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (response.data.status === 0) {
                    toast.error(response.data.message, {
                        //onClose: () => 
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setLoader(false);
                }
            } catch (error) {
                toast.error(error.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoader(false);
            }
        }
        if (Id) {
            getStore();
        }
    }, [Id, navigate, reset]);
    return (
        <div>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="form-control">
                    <TextField {...register("domain")} id="domain" label="Domain" variant="outlined" value={state.domain} onChange={handleChange} />
                    <p>{errors.domain?.message}</p>
                </div>
                <div className="form-control">
                    <TextField {...register("api_key")} id="api_key" label="API Key" variant="outlined" value={state.api_key} onChange={handleChange} />
                    <p>{errors.api_key?.message}</p>
                </div>
                <div className="form-control">
                    <TextField {...register("api_secret")} id="api_secret" label="API Secret" variant="outlined" value={state.api_secret} onChange={handleChange} />
                    <p>{errors.api_secret?.message}</p>
                </div>
                <div className="form-control">
                    <TextField {...register("api_password")} id="api_password" label="API Access Token" variant="outlined" value={state.api_password} onChange={handleChange} />
                    <p>{errors.api_password?.message}</p>
                </div>
                <div className="form-control">
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained">
                        <span>Save</span>
                    </LoadingButton>
                </div>

            </form>
        </div>
    )
}

export default CreateStore;