import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Moment from 'react-moment';

import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';


function UserList(props) {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [deleteUserId, setDeleteUserId] = useState();

    const deleteUser = (params) => {
        setDeleteUserId(params.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteUser = (userId) => {
        async function deleteUsers() {
            try {
                const response = await axios.post(process.env.REACT_APP_API_BASE_URL + 'user/delete/' + userId, null, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('user')
                    }
                });

                if (response.data.status === 1) {
                    setRows(JSON.parse(response.data.data));
                    handleClose();
                }
                else if (response.data.status === -1) {
                    localStorage.removeItem('user');
                    toast.error(response.data.message, {
                        onClose: () => { navigate('/Login') },
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (response.data.status === 0) {
                    toast.error(response.data.message, {
                        onClose: () => { handleClose() },
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            } catch (error) {
                toast.error(error.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        }

        deleteUsers();

    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70, sortable: true },
        { field: 'name', headerName: 'Name', width: 270, sortable: true },
        { field: 'email', headerName: 'Email', width: 270, sortable: true },
        {
            field: 'created_at', headerName: 'Created At', width: 250, sortable: true,
            renderCell: (params) => (
                <Moment format="DD-MM-YYYY kk:mm:ss A">
                    {params.row.created_at}
                </Moment>
            )
        },
        {
            field: 'action', headerName: 'Action', width: 250, sortable: true,
            renderCell: (params) => (
                <strong>
                    <IconButton
                        aria-label="edit"
                        color="success"
                        onClick={() => {
                            navigate('/CreateUser/' + params.id);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    {params.id !== 1 &&
                    <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => {
                            deleteUser(params);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>}
                </strong>
            )
        },
    ];

    useEffect(() => {

        async function getUsers() {
            await axios.get(process.env.REACT_APP_API_BASE_URL + 'users', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('user')
                }
            })
                .then((response) => {
                    if (response.data.status === 1) {
                        setRows(JSON.parse(response.data.data));
                    }
                    else if (response.data.status === -1) {
                        localStorage.removeItem('user');
                        toast.error(response.data.message, {
                            onClose: () => { navigate('/Login') },
                            position: "top-right",
                            autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                    else if (response.data.status === 0) {
                        toast.error(response.data.message, {
                            //onClose: () => 
                            position: "top-right",
                            autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error.message, {
                        //onClose: () => 
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                });


        }

        getUsers();
    }, [navigate]);

    return (
        <Container maxWidth="false">
        <div style={{ width: '100%' }}>
            <ToastContainer />

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"User Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete user ID {deleteUserId}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="error" autoFocus onClick={() => { handleDeleteUser(deleteUserId) }}>
                        Yes
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{textAlign: 'right', marginBottom: 20}}>
            <Button
                variant="contained"
                onClick={() => { navigate('/CreateUser') }}
                color="success"
                startIcon={<AddCircleIcon />}
            >Create a User</Button>
            </div>
            <div style={{ height: 632, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}

                />
            </div>
        </div>
        </Container>
    )


}
export default UserList;