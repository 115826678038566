import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Moment from 'react-moment';

import { DataGrid } from '@mui/x-data-grid';
import { Link } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineSharpIcon from '@mui/icons-material/DownloadForOfflineSharp';

const ThemeUpdateLog = forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [shopId, setShopId] = useState(0);
    const [domain, setDomain] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
        handleShopClick(shopId, domainName) {
            setShopId(shopId);
            setDomain(domainName);
            getThemeUpdateLog(shopId);
        }
        // handleClose() {  // If this function defined here then its not accessible to onclick of this component button
        //     setOpen(false);
        // }
    }));

    // useImperativeHandle(ref, () => { // This syntax is not working, giving error handleOpen is not defined
    //     function handleOpen() {
    //         setOpen(true);
    //     };

    //     return <div></div>
    // });

    const handleClose = () => {
        setOpen(false);
        setRows([]);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70, sortable: true },
        { field: 'shopify_theme_id', headerName: 'Theme ID', width: 170, sortable: true },
        { field: 'theme_name', headerName: 'Theme Name', width: 270, sortable: true },
        {
            field: 'created_at', headerName: 'Created At', width: 250, sortable: true,
            renderCell: (params) => (
                <Moment format="DD-MM-YYYY kk:mm:ss A">
                    {params.row.created_at}
                </Moment>
            )

        },
        {
            field: 'change_log', headerName: 'Change Log', headerAlign: 'center', width: 150, sortable: true,
            renderCell: (params) => (
                <Link href={process.env.REACT_APP_API_BASE_URL + "change-log/" + params.id}>
                    <IconButton
                        aria-label="Download Change Log"
                        color="success">
                        <DownloadForOfflineSharpIcon />
                    </IconButton>
                    Download</Link>
            )
        },
        {
            field: 'scan_log', headerName: 'Scan Log', headerAlign: 'center', width: 150, sortable: true,
            renderCell: (params) => (
                <Link href={process.env.REACT_APP_API_BASE_URL + "scan-log/" + params.id}>
                    <IconButton
                        aria-label="Download Change Log"
                        color="success">
                        <DownloadForOfflineSharpIcon />
                    </IconButton>
                    Download</Link>
            )

        },
    ];

    const getThemeUpdateLog = async (shopId) => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + "shop-theme-update-log/" + shopId,{
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('user')
                }
            }
            );

            if (response.data.status === 1) {
                setRows(JSON.parse(response.data.data));
            }
            else if (response.data.status === -1) {
                localStorage.removeItem('user');
                toast.error(response.data.message, {
                    onClose: () => { navigate('/Login') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            }
            else if (response.data.status === 0) {
                toast.error(response.data.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            }
        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    useEffect(() => {

    }, [shopId]);

    return (
        <div>
            <ToastContainer />
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'lg'}
                open={open}
                //onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Theme Update Log
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogTitle id="responsive-dialog-title">
                    {domain}
                </DialogTitle>
                <DialogContent>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                        />
                    </div>
                </DialogContent>
            </Dialog>

        </div>
    );
})

export default ThemeUpdateLog;