import React, { useState } from 'react';

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function CreateUser(props) {
    //const inputDomain = useRef<HTMLInputElement>(null);
    const { Id } = useParams();

    const navigate = useNavigate();
    var schema;
    if (Id) {
        schema = yup.object().shape({
            name: yup.string().required(),
            email: yup.string().email().required()
        });
    }
    else {
        schema = yup.object().shape({
            name: yup.string().required(),
            email: yup.string().email().required(),
            password: yup.string().required(),
            password_confirmation: yup.string().required()
        });
    }
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = React.useState(false);
    const [state, setState] = useState({ name: '', email: '' });

    const onSubmitHandler = async (data) => {
        //console.log(data);
        setLoading(true);
        var url;
        if (Id)
            url = process.env.REACT_APP_API_BASE_URL + 'user/update/' + Id;
        else
            url = process.env.REACT_APP_API_BASE_URL + 'register';

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('user')
        };

        try {
            const response = await axios.post(url, data, {
                headers: headers
            });

            if (response.data.status === 1) {
                toast.success(response.data.message, {
                    onClose: () => { navigate('/UserList') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                reset();
                setLoading(false);
            }
            else if (response.data.status === -1) {
                localStorage.removeItem('user');
                toast.error(response.data.message, {
                    onClose: () => { navigate('/Login') },
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                const msg = JSON.parse(response.data.message);
                Object.keys(msg).forEach((key) => {
                    msg[key].forEach((item) => {
                        toast.error(item, {
                            position: "top-right",
                            autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                    });
                });

                // Custom toast message with HTML
                // const Msg = ({ closeToast, toastProps }) => (
                //     <div className='toast-multi-line'>
                //         {msg_html.map((msgLine)=>
                //             <div>{msgLine}</div>
                //         )}

                //        {/* {toastProps.position} */}

                //       {/* <button>Retry</button>
                //       <button onClick={closeToast}>Close</button> */}
                //     </div>
                //   )            
                //   toast(<Msg />) 

                setLoading(false);
            }
        } catch (error) {
            toast.error(error.message, {
                //onClose: () => 
                position: "top-right",
                autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        const ctrlName = event.target.name;
        const value = event.target.value;
        setState({ ...state, [ctrlName]: value });
        reset({ ...state, [ctrlName]: value });
    }
    useEffect(() => {
        async function getUser() {
            try {
                setLoader(true);
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'user/' + Id, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('user')
                    }
                });

                if (response.data.status === 1) {
                    //setRows(JSON.parse(response.data.data));
                    const user = JSON.parse(response.data.data);
                    setState({ name: user.name, email: user.email });
                    reset({ name: user.name, email: user.email });
                    setLoader(false);
                }
                else if (response.data.status === -1) {
                    localStorage.removeItem('user');
                    toast.error(response.data.message, {
                        onClose: () => { navigate('/Login') },
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else if (response.data.status === 0) {
                    toast.error(response.data.message, {
                        //onClose: () => 
                        position: "top-right",
                        autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setLoader(false);
                }
            } catch (error) {
                toast.error(error.message, {
                    //onClose: () => 
                    position: "top-right",
                    autoClose: parseInt(process.env.REACT_APP_TOAST_AUTOCLOSE),
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoader(false);
            }
        }
        if (Id) {
            getUser();
        }

    }, [Id, navigate, reset]);
    return (
        <div>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="form-control">
                    
                    <TextField {...register("name")} id="name" variant="outlined" label="Name" value={state.name} onChange={handleChange} />
                    <p>{errors.name?.message}</p>
                </div>
                <div className="form-control">
                    
                    <TextField {...register("email")} id="email" variant="outlined" label="Email" value={state.email} onChange={handleChange} />
                    <p>{errors.email?.message}</p>
                </div>
                <div className="form-control">
                    
                    <TextField {...register("password")} type="password" id="password" label="Password" variant="outlined" />
                    <p>{errors.password?.message}</p>
                </div>
                <div className="form-control">
                    
                    <TextField {...register("password_confirmation")} type="password" id="password_confirmation" label="Confirmed Password" variant="outlined" />
                    <p>{errors.password_confirmation?.message}</p>
                </div>

                <div className="form-control">
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained">
                        <span>Save</span>
                    </LoadingButton>
                </div>

            </form>
        </div>
    )
}

export default CreateUser;